<template>
	<section>
		<b-container fluid class="pagecontainer">
			<b-row>
				<b-col>
					<b-alert variant="danger" v-if="error" show dismissible>
						ERROR: {{error}}
					</b-alert>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-card no-body>
						<b-card-text>
							<span class="pl-2 h3">
								<slot name="title">
									Game Definitions
								</slot>
							</span>
							<div class="float-right">
								<b-form inline>
									<b-form-input v-model.trim="searchText" placeholder="search text" />
									<b-btn variant="success" @click="doReset">
										<fa-icon icon="sync" />
									</b-btn>
									<b-btn variant="success" @click="doGetGameDefs" v-if="definitions && definitions.nextPageToken">
										<fa-icon icon="chevron-down" />
									</b-btn>
								</b-form>
							</div>
							<br />
							<b-tabs>
								<b-tab>
									<b-table striped hover responsive :fields="gamedefsfields" :items="definitions">
										<template #cell(#)="data">
											{{data.index+1}}
										</template>
										<template #cell(rules)="data">
											<div>
												<div v-for="(value, name) in data.value">{{name}} = {{value}}</div>
											</div>
										</template>
										<template #cell(dates)="data">
											Created: {{ data.item.createdOnUtcMs ? new Date(data.item.createdOnUtcMs) : null }}
											<br />
											Modified: {{ data.item.lastModifiedOnUtc ? new Date(data.item.lastModifiedOnUtcMs) : null }}
										</template>
										<template #cell(gameType)="data">
											Type: {{ data.item.type }}
											<br />
											Table: {{ data.item.environment.tableType }}
											<br />
											Room: {{ data.item.environment.roomType }}
											<br />
											Card: {{ data.item.environment.cardType }}
											<br />
											Currency: {{ data.item.currency }}
											<br />
										</template>
										<template #cell()="data">
											<i>{{ data.value }}</i>
										</template>
										<template #cell(id)="data">
											<i>{{ data.value }}</i>
											<br />
											<b-button-group>
												<router-link :to="{name: 'create-room', params: { id: data.item.id } }">
													<b-btn>Edit Def</b-btn>
												</router-link>
												<b-btn class="ml-2" @click="doCreateGame(data.item)">Create Game</b-btn>
											</b-button-group>
										</template>
									</b-table>
								</b-tab>
							</b-tabs>
						</b-card-text>
					</b-card>
				</b-col>
			</b-row>
		</b-container>
	</section>
</template>
<script lang="js">
	import { mapActions } from 'vuex';
	import DEBUG from '@/common/DEBUG';

	export default {
		name: 'manage_gamedefs',

		components: {
		},

		data() {
			return {
				baseurl: '//' + window.location.hostname,

				error: null,
				isprocessing0: false,
				searchText: null,
				gamedefsfields: [
					{ key: '#', label: '#' },
					{ key: 'id', label: 'Id' },
					{ key: 'name', label: 'Name' },
					{ key: 'currency', label: 'Currency' },
					{ key: 'dates', label: 'Dates' },
					{ key: 'gameType', label: 'Type' },
					{ key: 'rules', label: 'Rules' },
					{ key: 'gameState', label: 'State' },
				],
				definitions: {
					nextPageToken: null,
					definitions: []
				},
			}
		},

		async mounted() {
			this.doGetGameDefs();
		},

		methods: {
			...mapActions({
				getGameDefinitions: "getGameDefinitions",
				createGame: "createGame",
			}),

			async doReset() {
				const that = this
				DEBUG.log('doReset...')

				that.error = null;
				try {
					const searchText = this.searchText;
					const results = await that.getGameDefinitions({ searchText});
					DEBUG.log('doReset - RESULTS - ', results);
					that.definitions.nextPageToken = results.nextPageToken;
					that.definitions.definitions = results.definitions;
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${that.error}`)
				}
			},

			async doGetGameDefs() {
				const that = this
				DEBUG.log('doGetGameDefs...')

				that.error = null;
				try {
					const { nextPageToken } = that.definitions;
					const filter = { nextPageToken };
					const results = await that.getGameDefinitions(filter);
					DEBUG.log('doGetGameDefs - RESULTS - ', results)
					that.definitions.nextPageToken = results.nextPageToken;
					that.definitions.definitions.push(...results.definitions.map(u => {
						u.isprocessing = false;
						return u;
					}));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${that.error}`)
				}
			},

			async doCreateGame(item) {
				DEBUG.log('doCreateGame...')

				const that = this
				const { id } = item

				that.error = null;
				try {
					const game = await that.createGame(id);
					if (game) {
						DEBUG.log('GAME - ', game)
						alert('game created - ' + game.id);
					}
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

		}
	}
</script>
<style>
	.pagecontainer {
		max-height: 85vh;
		overflow-y: auto;
	}

	.column5 {
		width: 5%;
	}

	.column20 {
		width: 20%;
	}
</style>